import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Paper,
  Radio,
  Button,
  Dialog,
  TextField,
  IconButton,
  RadioGroup,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  CircularProgress,
  FormControlLabel,
  DialogContentText,
} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBack';

import i18n from '_utils/i18n';
import Menu from '_screens/Menu';
import master from '_utils/master';
import * as Push from '_redux/push';
import { Screen } from '_components/core';
import { createValidationSchema, validators } from '_utils/validation';

let formDisabled = false;
/* */
const validationSchema = createValidationSchema({
  body: validators.required,
  name: validators.required,
  title: validators.required,
});

/* */
const useFormStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const CampaignForm = ({
  errors,
  values,
  handleBlur,
  handleChange,
  handleSubmit,
}) => {
  const classes = useFormStyles();

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        required
        fullWidth
        name="name"
        margin="normal"
        variant="outlined"
        onBlur={handleBlur}
        disabled={formDisabled}
        value={values?.name || ''}
        onChange={handleChange}
        error={!!(errors.name)}
        label="Nom de la campagne"
        className={classes.textField}
        helperText="Choisissez un nom pour votre campagne qui vous permettra de la retrouver facilement. ex: 'Promotion septembre 2019'"
      />
      <TextField
        required
        fullWidth
        name="title"
        margin="normal"
        variant="outlined"
        onBlur={handleBlur}
        disabled={formDisabled}
        value={values?.title || ''}
        onChange={handleChange}
        error={!!(errors.title)}
        className={classes.textField}
        label="Titre de la notification"
        helperText="Titre de la notification qui apparait dans le centre de notification du contact."
      />
      <TextField
        rows="3"
        required
        fullWidth
        multiline
        name="body"
        margin="normal"
        label="Message"
        variant="outlined"
        onBlur={handleBlur}
        disabled={formDisabled}
        value={values?.body || ''}
        onChange={handleChange}
        error={!!(errors.body)}
        className={classes.textField}
        helperText="Contenu de la notification qui apparait quand la notification est ouverte par le contact."
      />
      <TextField
        fullWidth
        name="data.link"
        margin="normal"
        variant="outlined"
        onBlur={handleBlur}
        disabled={formDisabled}
        value={values?.data?.link || ''}
        onChange={handleChange}
        error={!!(errors.link)}
        className={classes.textField}
        label="Lien de redirection (optionnel)"
        helperText="Lien vers lequel sera redirigé le contact lorsqu'il cliquera sur la notification."
      />
    </form>
  );
};

/* */
const useStyles = makeStyles((theme) => ({
  subtitle: {
    textAlign: 'left',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    // border: '1px solid #fff',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.dark,
  },
  label: { marginRight: theme.spacing() },
}));

/* */
const mapStateToProps = ({ app: { golf } }) => ({ golf });

export default connect(mapStateToProps)(({ match: { params: { id } }, history, golf }) => {
  const classes = useStyles();

  const formRef = React.useRef();

  const [sent, setSent] = React.useState(false);
  const [item, setItem] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [pushList, setPushList] = React.useState('');
  const [pushLists, setPushLists] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);

  const [deleting, setDeleting] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);

  const [sending, setSending] = React.useState(false);
  const [confirmSend, _setConfirmSend] = React.useState(false);

  const setConfirmSend = (value) => {
    _setConfirmSend(value);
  };

  React.useEffect(() => {
    Promise.resolve()
      .then(() => setLoading(true))
      .then(() => Push.api.getPushCampaign(master, { id, ignoreStatus: true }))
      .then(({ createdAt, data, ...rest }) => setItem({
        ...rest,
        createdAt: createdAt && i18n.l('date.formats.default', new Date(createdAt)),
        data: JSON.parse(data),
      }))
      .catch((e) => setError(e))
      .finally(() => setLoading(false));
  }, [id, sent]);

  React.useEffect(() => {
    setSent(item && Boolean(item.sentAt));
    formDisabled = sent;
    setPushList(item && item.pushListId);
  }, [item, sent]);

  function handleOpenDialog() {
    Promise.resolve()
      .then(() => setOpenDialog(true))
      .then(() => Push.api.getPushLists(master, { golfId: golf.id }))
      .then((list) => list && setPushLists(list))
      .catch((e) => setError(e));
  }

  function handleSetPushList() {
    setItem({
      ...item,
      pushListId: pushList,
      pushList: pushLists.find((p) => p.id === pushList),
    });
    setOpenDialog(false);
  }

  function save(values) {
    setIsSubmitting(true);
    if (values && values.data) {
      try {
        item.data = JSON.parse(values.data);
      } catch (e) {
        item.data = values.data;
      }
    }
    // eslint-disable-next-line prefer-object-spread
    const data = Object.assign({}, {
      ...item,
      ...values,
      data: JSON.stringify(item.data),
    });
    delete data.pushList;

    return Push.api.setPushCampaign(master, data)
      .then((campaign) => setItem(campaign))
      .catch((e) => setError(e))
      .finally(() => setIsSubmitting(false));
  }

  /* */
  function handleSend() {
    try {
      item.data = JSON.parse(item.data);
    } catch (e) {
      // console.log(e)
    }
    setSending(true);
    save()
      .then(() => Push.api.sendPushCampaign(master, { id }))
      .then((res) => {
        if (res.success) {
          return setSuccess('Campagne envoyée');
        }
        if (!item.pushList.id) {
          return setError('Veuillez choisir une liste de destinataires');
        }
        return setError("Erreur lors de l'envoi de la campagne");
      })
      .then(() => {
        formDisabled = true;
        setSent(true);
      })
      .catch((e) => setError(e))
      .finally(() => {
        setSending(false);
        setConfirmSend(false);
      });
  }

  /* */
  function handleDelete() {
    setDeleting(true);
    Push.api.deletePushCampaign(master, { id })
      .then(() => setSuccess('Supprimé'))
      .then(() => history.goBack())
      .catch((e) => setError(e))
      .finally(() => { setDeleting(false); setConfirmDelete(false); });
  }

  return (
    <Screen
      error={error}
      layout='fluid'
      title='Campagne'
      loading={loading}
      success={success}
      menu={(<Menu />)}
      >
      <Box display='flex' height='100%' justifyContent='space-between' paddingLeft={2} paddingRight={2} paddingBottom={2}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={history.goBack}
          className={classes.backButton}>
          <BackIcon />
        </IconButton>
      </Box>
        {item && (
          <React.Fragment>
             <Box mb={2} display="flex" flex={1} flexDirection='column'>
              <Paper>
                 <Typography
                   variant="subtitle1"
                   align="center"
                   className={classes.subtitle}>
                   {'Informations'}
                 </Typography>
                 <Box mx={2} my={1}>
                   <Formik
                     innerRef={formRef}
                     readOnly={sent}
                     initialValues={{
                       body: item.body || '',
                       name: item.name || '',
                       title: item.title || '',
                       data: item.data,
                     }}
                     onSubmit={save}
                     component={CampaignForm}
                     validationSchema={validationSchema} />
                 </Box>
              </Paper>
             </Box>
             <Box mb={2} display="flex" flex={1} flexDirection='column'>
              <Paper>
               <Typography
                 variant="subtitle1"
                 align="center"
                 className={classes.subtitle}>
                 {'Destinataires'}
               </Typography>
               <Box
                 p={2}
                 display="flex"
                 flexDirection="column"
                 justifyContent="space-evenly">
                 <Box mx={2} my={1}>
                   <Typography variant="subtitle1" component="span" className={classes.label}>{"Liste d'envoi : "}</Typography>
                   <Typography variant="body1" component="span" onClick={handleOpenDialog}>{(item.pushList && item.pushList.name) || 'Choisir une liste '}<br/></Typography>
                 </Box>
                 <Box mx={2} my={1}>
                   <Typography variant="subtitle1" component="span" className={classes.label}>{'Nombre de destinataires total: '}</Typography>
                   <Typography variant="body1" component="span">{(item.pushList && item.pushList.accounts && item.pushList.accounts.length) || 0}</Typography>
                 </Box>
                 {!sent && (
                   <Box mx={2} my={1}>
                        <Button
                      color="secondary"
                      variant="outlined"
                      onClick={handleOpenDialog}
                      disabled={isSubmitting || sending || deleting}>
                       {'Choisir une liste de diffusion'}
                     </Button>
                   </Box>
                 )}
                  </Box>
              </Paper>
             </Box>
             <br />
             <Box mb={2} display="flex" flex={1} flexDirection='column'>
               <Box
                 p={2}
                 display="flex"
                 flexDirection="row"
                 justifyContent="space-between">
                 {item.sentAt && (
                   <Box display='flex' flexDirection="column">
                     <Typography variant="caption" display="block">{"Date d'envoi"}</Typography>
                     <Typography variant="body2">{moment(item.sentAt).format('DD/MM/YYYY HH:mm:ss')}</Typography>
                   </Box>
                 )}
                 {!sent && (
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() => setConfirmSend(true)}
                      disabled={isSubmitting || sending || deleting}
                    >
                      {'Envoyer'}
                    </Button>
                 )}
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => setConfirmDelete(true)}
                    disabled={isSubmitting || sending || deleting}
                  >
                    {'Supprimer'}
                  </Button>
                  {!sent && (
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={isSubmitting || sending || deleting}
                      onClick={() => {
                        if (formRef.current) formRef.current.submitForm();
                      }}
                    >
                      {'Enregistrer'}
                    </Button>
                  )}
                </Box>
              </Box>
           </React.Fragment>
        )}

        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          >
          <DialogTitle>{'Choisir la liste des destinataires'}</DialogTitle>
          <DialogContent>
            {(pushLists === null) && <CircularProgress />}
            {(pushLists !== null) && (
              <RadioGroup
                name="pushList"
                value={pushList}
                  aria-label="pushList"
                  onChange={(event) => setPushList(event.target.value)}
                >
                  {pushLists.map((option) => (
                    <FormControlLabel
                      key={option.id}
                      value={option.id}
                      control={<Radio />}
                      label={option.name}
                    />
                  ))}
                </RadioGroup>
            )}
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary" style={{ float: 'left' }}>
              {'Fermer'}
            </Button>
            <Button color="primary" variant="contained" disabled={(pushLists === null) || deleting || sending} onClick={handleSetPushList}>
              {'Enregistrer'}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmSend}
          onClose={() => setConfirmSend(false)}
          >
          <DialogTitle>{'Tout est prêt pour envoyer votre campagne !'}</DialogTitle>
          <DialogContent>
            <Box mb={2}>
              <Typography align="center">{"Relisez le résumé ci-dessous avant d'envoyer votre campagne."}</Typography>
              <br />
              <Paper className={classes.paper}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  className={classes.subtitle}>
                  {'Paramètrage'}
                </Typography>
                <Box
                  p={2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-evenly">
                  <Box mx={2} my={1}>
                    <Typography variant="subtitle1" component="span" className={classes.label}>{'Nom : '}</Typography>
                    <Typography variant="body1" component="span">{item && item.name}</Typography>
                  </Box>
                  <Box mx={2} my={1}>
                    <Typography variant="subtitle1" component="span" className={classes.label}>{'Titre : '}</Typography>
                    <Typography variant="body1" component="span">{item && item.title}</Typography>
                  </Box>
                  <Box mx={2} my={1}>
                    <Typography variant="subtitle1" component="span" className={classes.label}>{'Message : '}</Typography>
                    <Typography variant="body1" component="span">{item && item.body.replace(/\\n/gi, '<br />')}</Typography>
                  </Box>
                  {item && item.data && item.data.link && (
                    <Box mx={2} my={1}>
                      <Typography variant="subtitle1" component="span" className={classes.label}>{'Lien de redirection : '}</Typography>
                      <Typography variant="body1" component="span">{String(item.data.link)}</Typography>
                    </Box>
                  )}
                </Box>
                </Paper>
                <br />
                <Paper className={classes.paper}>
                <Typography
                  variant="subtitle1"
                  align="center"
                  className={classes.subtitle}>
                  {'Destinataires'}
                </Typography>
                <Box
                  p={2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-evenly">
                  <Box mx={2} my={1}>
                    <Typography variant="subtitle1" component="span" className={classes.label}>{"Liste(s) d'envoi : "}</Typography>
                    {item && item.pushList && (<Typography variant="body1" component="span">{item.pushList.name}<br/></Typography>)}
                  </Box>
                  <Box mx={2} my={1}>
                    <Typography variant="subtitle1" component="span" className={classes.label}>{'Nombre de destinataires total: '}</Typography>
                    <Typography variant="body1" component="span">{(item && item.pushList && item.pushList.accounts && item.pushList.accounts.length) || 0}</Typography>
                  </Box>
                </Box>
              </Paper>
              </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setConfirmSend(false)} color="primary" style={{ float: 'left' }}>
              {'Fermer'}
            </Button>
            <Button color="primary" variant="contained" disabled={(sending)} onClick={handleSend}>
              {'Envoyer'}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmDelete}
          onClose={() => setConfirmDelete(false)}
          >
          <DialogTitle>{'Supprimer cette campagne'}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {'Attention, vous êtes sur le point de supprimer cette campagne.'}
              <br />
              {'Cette action est irréversible.'}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setConfirmDelete(false)} color="primary" style={{ float: 'left' }}>
              {'Fermer'}
            </Button>
            <Button color="primary" variant="contained" disabled={(deleting)} onClick={handleDelete}>
              {'Confirmer la suppression'}
            </Button>
          </DialogActions>
        </Dialog>
    </Screen>
  );
});
