import React from 'react';
import MaterialTable from 'material-table';
import { connect } from 'react-redux';
import {
  Box,
  Button,
} from '@material-ui/core';
import i18n from '_utils/i18n';

import Menu from '_screens/Menu';
import master from '_utils/master';
import * as Push from '_redux/push';
import * as Client from '_redux/client';
// import * as Contact from '_redux/contact';
import { Screen } from '_components/core';

import {
  useQuery,
} from 'react-query';
import AddToList from './AddToList';
import CreateList from './CreateList';
import SendNotification from './SendNotification';

const mapStateToProps = ({ app: { golf } }) => ({ golf });

export default connect(mapStateToProps)(({ golf }) => {
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);

  const { isError, isLoading, data } = useQuery(['users', { golfId: golf.id }], () => Client.api.getAccountsWithPushToken(master, { golfId: golf.id }).then((accounts) => accounts.map(({ matches, ...a }) => ({
    ...a,
    match: matches && matches.find((m) => m.golfId === golf.id),
  }))));

  const [selected, setSelected] = React.useState([]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [openAddToList, setOpenAddToList] = React.useState(false);
  const [openCreateList, setOpenCreateList] = React.useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);

  /* */
  const createList = (name) => {
    setIsSubmitting(true);
    Push.api.createList(master, { name, accounts: selected.map(({ id }) => id) })
      // .then((list) => console.log(list))
      .then(() => setSuccess('Liste créée avec succès.'))
      .catch((e) => setError(e))
      .finally(() => {
        setIsSubmitting(false);
        setOpenCreateList(false);
      });
  };

  /* */
  const addToList = (pushList) => {
    setIsSubmitting(true);
    Push.api.pushListAddAccounts(master, { accounts: selected.map(({ id }) => id), id: pushList })
      .then(() => setSuccess('Ajout réalisé avec succès.'))
      .catch((e) => setError(e))
      .finally(() => {
        setIsSubmitting(false);
        setOpenAddToList(false);
      });
  };

  // --------------------------------------------------------------------------
  // Send notification message
  // --------------------------------------------------------------------------

  /* */
  const sendNotification = ({ body, title, sendData }) => {
    setIsSubmitting(true);
    return Push.api.createMessage(master, {
      body,
      title,
      name: title,
      data: JSON.stringify(sendData),
      accounts: selected.map(({ id }) => id),
    })
      .then(({ id }) => Push.api.sendMessage(master, { id }))
      // .then((list) => console.log(list))
      .then(() => setSuccess('Notification envoyée.'))
      .catch((e) => setError(e))
      .finally(() => {
        setIsSubmitting(false);
        setOpenNotification(false);
      });
  };

  /* */
  return (
    <Screen
      error={isError || error}
      layout='fluid'
      title='Contacts'
      loading={isLoading}
      success={success}
      menu={<Menu />}
    >
      <Box
        mb={2}
        display="flex"
        flexDirection="row"
        visibility={(selected && selected.length > 0) ? 'visible' : 'hidden'}
        >
        <Box m={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpenCreateList(true)}>
            {'Créer une liste'}
          </Button>
        </Box>
        <Box m={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpenAddToList(true)}>
            {'Ajouter à une liste'}
          </Button>
        </Box>
        <Box m={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpenNotification(true)}>
            {'Envoyer une notification'}
          </Button>
        </Box>
      </Box>
      <MaterialTable
          title=""
          data={data}
          onSelectionChange={(rows) => setSelected(rows)}
          localization={{
            toolbar: {
              nRowsSelected: `{0} ${i18n.t('MUIDataTable.selectedRows.text')}`,
              searchPlaceholder: i18n.t('MUIDataTable.toolbar.search'),
              searchTooltip: i18n.t('MUIDataTable.toolbar.search'),
            },
            body: {
              filterRow: {
                filterTooltip: i18n.t('MUIDataTable.toolbar.filterTable'),
              },
            },
          }}
          options={{
            paging: true,
            pageSize: 10,
            toolbar: true,
            selection: true,
            filtering: true,
            showTitle: false,
            showHeader: false,
            toolbarButtonAlignment: 'right',
            pageSizeOptions: [10, 50, 100, 150, 200],
            maxBodyHeight: '80vh',
          }}
          columns={[
            { field: 'id', hidden: true },
            { title: 'Prenom', field: 'firstname', defaultSort: 'asc' },
            { title: 'Nom', field: 'lastname' },
            { title: 'email', field: 'email' },
            { title: 'tel', field: 'phone' },
            {
              title: 'Type',
              field: 'match.isMember',
              lookup: { true: 'Membre', false: 'Visiteur' },
            },
          ]}
        />
      <CreateList
        open={Boolean(selected?.length) && openCreateList}
        onSubmit={createList}
        isSubmitting={isSubmitting}
        nbSelected={selected?.length}
        onClose={() => setOpenCreateList(false)}
        />
      <SendNotification
        open={Boolean(selected?.length) && openNotification}
        onSubmit={sendNotification}
        isSubmitting={isSubmitting}
        nbSelected={selected?.length}
        onClose={() => setOpenNotification(false)}
        />
      <AddToList
        open={Boolean(selected?.length) && openAddToList}
        onSubmit={addToList}
        isSubmitting={isSubmitting}
        nbSelected={selected?.length}
        onClose={() => setOpenAddToList(false)}
        />
    </Screen>
  );
});
