export default {
  gql: {
    /*
     * PUSH LIST
     */
    GET_PUSH_LISTS: `
      query {
        getPushLists {
          id
          name
          createdAt
          updatedAt
          accounts
        }
      }
    `,
    GET_PUSH_LIST: `
      query($id: String!) {
        getPushList(id: $id) {
          name
          createdAt
          updatedAt
          accounts
        }
      }
    `,
    PUSH_LIST_ADD_ACCOUNTS: `
      mutation(
        $id: String!
        $accounts: [String]!
      ) {
        pushListAddAccounts(
          id: $id
          accounts: $accounts
        ) {
          success
          message
        }
      }
      `,
    PUSH_LIST_DELETE_ACCOUNTS: `
      mutation(
        $id: String!
        $accounts: [String]!
      ) {
        pushListDeleteAccounts(
          id: $id
          accounts: $accounts
        ) {
          success
          message
        }
      }
    `,
    SET_PUSH_LIST: `
      mutation(
        $id: String
        $name: String!
        $accounts: [String]
      ) {
          setPushList(
            id: $id
            name: $name
            accounts: $accounts
          ) {
            name
            accounts
          }
        }
    `,
    DELETE_PUSH_LIST: `
      mutation(
        $id: String!
      ) {
        deletePushList(
          id: $id
        ) {
          success
        }
      }
    `,
    /*
     * PUSH MESSAGE
     */
    GET_PUSH_MESSAGES: `
      query {
        getPushMessages {
          id
          data
          body
          sound
          title
          sentAt
          subtitle
          priority
          updatedAt
          createdAt
          expiration
          pushCampaignId
          accounts {
            id
          }
        }
      }
    `,
    GET_PUSH_MESSAGE: `
      query($id: String!) {
        getPushMessage(id: $id) {
          data
          body
          sound
          title
          sentAt
          subtitle
          priority
          updatedAt
          createdAt
          expiration
          pushCampaignId
          accounts {
            id
          }
        }
      }
    `,
    SET_PUSH_MESSAGE: `
      mutation(
        $id: String
        $data: String
        $body: String!
        $sound: String
        $title: String!
        $subtitle: String
        $priority: String
        $golfId: String
        $accounts: [String]
        $expiration: String
        $pushCampaignId: String
      ) {
        setPushMessage(
          id: $id
          data: $data
          body: $body
          sound: $sound
          title: $title
          accounts: $accounts
          subtitle: $subtitle
          priority: $priority
          golfId: $golfId
          expiration: $expiration
          pushCampaignId: $pushCampaignId
        ) {
          id
          data
          body
          sound
          title
          subtitle
          priority
          createdAt
          updatedAt
          expiration
          pushCampaignId
          accounts {
            id
          }
        }
      }
    `,
    SEND_PUSH_MESSAGE: `
      mutation($id: String!) {
        sendPushMessage(id: $id) {
          success
        }
      }
    `,
    /*
     * PUSH CAMPAIGN
     */
    GET_PUSH_CAMPAIGNS: `
      query($golfId: String) {
        getPushCampaigns(golfId: $golfId) {
          id
          data
          body
          name
          sound
          title
          sentAt
          subtitle
          priority
          updatedAt
          expiration
          createdAt
          pushListId
          pushMessageId
        }
      }
    `,
    GET_PUSH_CAMPAIGN: `
      query(
        $id: String!
        $ignoreStatus: Boolean
      ) {
        getPushCampaign(
          id: $id
          ignoreStatus: $ignoreStatus
        ) {
          id
          data
          body
          name
          sound
          title
          sentAt
          subtitle
          priority
          updatedAt
          expiration
          createdAt
          pushListId
          pushMessageId
          pushList {
            id
            name
            accounts
          }
        }
      }
    `,
    SET_PUSH_CAMPAIGN: `
      mutation(
        $id: String
        $body: String
        $data: String
        $name: String
        $title: String
        $golfId: String
        $pushListId: String
      ) {
        setPushCampaign(
          id: $id
          body: $body
          data: $data
          name: $name
          title: $title
          golfId: $golfId
          pushListId: $pushListId
        ) {
          id
          data
          body
          name
          sound
          title
          sentAt
          subtitle
          priority
          updatedAt
          expiration
          createdAt
          pushListId
          pushMessageId
          pushList {
            id
            name
            accounts
          }
        }
      }
    `,
    DELETE_PUSH_CAMPAIGN: `
      mutation($id: String!) {
        deletePushCampaign(id: $id) {
          success
        }
      }
    `,
    SEND_PUSH_CAMPAIGN: `
      mutation($id: String!) {
        sendPushCampaign(id: $id) {
          success
        }
      }
    `,
  },
};
