import React from 'react';
import moment from 'moment';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { createValidationSchema, validators } from '_utils/validation';

import config from '_config';
/* */
const validationSchema = createValidationSchema({
  body: validators.required,
  title: validators.required,
});

/* */
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  confirmNotification: {
    whiteSpace: 'pre-line',
    padding: theme.spacing(2),
    borderBottom: '1px solid #ccc',
  },
}));

/* */
export default (({
  open,
  onClose,
  onSubmit,
  nbSelected,
  isSubmitting,
}) => {
  const classes = useStyles();

  const [body, setBody] = React.useState('');
  const [title, setTitle] = React.useState('');
  const [data, setData] = React.useState({});
  const [confirm, setConfirm] = React.useState(false);

  // Reset name on open/close
  React.useEffect(() => {
    setBody(config.pushDebug ? `Le ${moment().format('DD/MM/YYYY')} à ${moment().format('HH:mm:ss')} par Julien` : '');
    setTitle(config.pushDebug ? 'Notification de test' : '');
    setData({});
    setConfirm(false);
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}>
        <DialogTitle id="openNotification-title">{'Envoyer une notification.'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="openNotification-description">
            {'Envoyer une notification aux contacts sélectionnés.'}
          </DialogContentText>
          <Box mb={2}>
            <Formik
              onSubmit={(values) => {
                setBody(values.body);
                setTitle(values.title);
                setData({
                  link: values.link,
                });
                setConfirm(true);
              }}
              initialValues={{ body, title, link: data && data.link }}
              validationSchema={validationSchema}>
              {({
                errors,
                values,
                handleBlur,
                handleChange,
                handleSubmit,
              }) => (
                <form
                  id="openNotification-form"
                  onSubmit={handleSubmit}
                  className={classes.container}
                  autoComplete="off">
                  <TextField
                    required
                    fullWidth
                    name="title"
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.title}
                    onChange={handleChange}
                    error={!!(errors.title)}
                    className={classes.textField}
                    label="Titre de la notification"
                    helperText="Titre de la notification qui apparait dans le centre de notification du contact."
                  />
                  <TextField
                    rows="3"
                    required
                    fullWidth
                    multiline
                    name="body"
                    margin="normal"
                    label="Message"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.body}
                    onChange={handleChange}
                    error={!!(errors.body)}
                    className={classes.textField}
                    helperText="Contenu de la notification qui apparait quand la notification est ouverte par le contact."
                  />
                  <TextField
                    fullWidth
                    name="link"
                    margin="normal"
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.link || ''}
                    onChange={handleChange}
                    error={!!(errors.link)}
                    className={classes.textField}
                    label="Lien de redirection (optionnel)"
                    helperText="Lien vers lequel sera redirigé le contact lorsqu'il cliquera sur la notification."
                  />
                </form>
              )}
            </Formik>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {'Fermer'}
          </Button>
          <Button color="primary" variant="contained" autoFocus type="submit" form="openNotification-form">
            {'Envoyer'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open && confirm}
        onClose={onClose}>
        <DialogTitle id="confirmNotification-title">{'Êtes-vous sûr de vouloir envoyer cette notification ?'}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" className={classes.confirmNotification}>{`${nbSelected} contacts sélectionnés`}</Typography>
          <Typography variant="subtitle1" className={classes.confirmNotification}>{title}</Typography>
          <Typography variant="body2" className={classes.confirmNotification}>{body}</Typography>
          {data && data.link && (
            <Typography variant="body2" className={classes.confirmNotification}>{data.link}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <form className={classes.container} autoComplete="off">
            <Button onClick={onClose} color="primary">
              {'Annuler'}
            </Button>
            <Button onClick={() => onSubmit({ body, title, sendData: data })} disabled={isSubmitting} color="primary" variant="contained" autoFocus>
              {'Ok'}
            </Button>
          </form>
        </DialogActions>
      </Dialog>
    </>
  );
});
