import { connect } from 'react-redux';
import MaterialTable from 'material-table';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  useQuery,
} from 'react-query';
import {
  Box,
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';

import i18n from '_utils/i18n';
import Menu from '_screens/Menu';
import master from '_utils/master';
import * as Push from '_redux/push';
import { Screen } from '_components/core';

/* */
const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const mapStateToProps = ({ app: { golf } }) => ({ golf });

export default connect(mapStateToProps)(({ golf, history }) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const {
    isLoading,
    isError,
    data,
  } = useQuery(['campaigns', { golfId: golf.id }], () => Push.api.getPushCampaigns(master, { golfId: golf.id }).then((res) => res.map(({
    accounts,
    sentAt,
    createdAt,
    updatedAt,
    ...rest
  }) => ({
    ...rest,
    sentAt: sentAt && new Date(sentAt),
    createdAt: createdAt && new Date(createdAt),
    updatedAt: updatedAt && new Date(updatedAt),
    accounts: accounts && accounts.map((account) => account.id),
    sentAtTime: sentAt && i18n.l('time.formats.short_y', new Date(sentAt)),
    createdAtTime: createdAt && i18n.l('time.formats.short_y', new Date(createdAt)),
    updatedAtTime: updatedAt && i18n.l('time.formats.short_y', new Date(updatedAt)),
  }))));
  /* */
  const [openDialog, setOpenDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // New campaign
  const [name, setName] = useState('');
  const [body, setBody] = useState('');
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');

  function handleOpenDialog() {
    setName('');
    setBody('');
    setTitle('');
    setLink('');
    setOpenDialog(true);
  }

  function handleCreateCampaign() {
    setIsSubmitting(true);
    Push.api.setPushCampaign(master, {
      name,
      body,
      title,
      data: JSON.stringify({ link }),
      golfId: golf.id,
    })
      .then(({ id }) => history.push(`/campaign/${id}`))
      .catch((e) => setError(e))
      .finally(() => setIsSubmitting(false));
  }
  /* */
  return (
    <Screen
      error={isError || error}
      layout='fluid'
      title='Campagnes'
      loading={isLoading}
      menu={(<Menu />)}
      >
        <Box mb={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleOpenDialog}>
            Créer une campagne
          </Button>
        </Box>
        <MaterialTable
          data={data}
          title="Campagnes"
          options={{
            paging: true,
            pageSize: 10,
            toolbar: false,
            filtering: true,
            selection: false,
            showTitle: false,
            showHeader: false,
            toolbarButtonAlignment: 'right',
            pageSizeOptions: [10, 50, 100, 150, 200],
            maxBodyHeight: '80vh',
          }}
          columns={[
            { field: 'id', hidden: true },
            { title: 'Nom', field: 'name' },
            {
              title: 'Date de création',
              field: 'createdAtTime',
              defaultSort: 'desc',
              customSort: (a, b) => a.createdAt - b.createdAt,
            },
            {
              title: 'Date de modification',
              field: 'updatedAtTime',
              customSort: (a, b) => a.updatedAt - b.updatedAt,
            },
            {
              title: 'Date d\'envoi',
              field: 'sentAtTime',
              customSort: (a, b) => a.sentAt - b.sentAt,
            },
          ]}
          onRowClick={(event, item) => history.push(`/campaign/${item.id}`)}
        />

        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          >
          <DialogTitle>{'Créer une nouvelle campagne'}</DialogTitle>
          <DialogContent>
            <TextField
              required
              fullWidth
              name="name"
              value={name}
              margin="normal"
              variant="outlined"
              label="Nom de la campagne"
              className={classes.textField}
              onChange={(e) => { setName(e.currentTarget.value); }}
              helperText="Choisissez un nom pour votre campagne qui vous permettra de la retrouver facilement. ex: 'Promotion de rentrée'"
            />
            <TextField
              fullWidth
              name="title"
              value={title}
              margin="normal"
              variant="outlined"
              label="Titre de la notification"
              className={classes.textField}
              helperText="Titre de la notification qui apparait dans le centre de notification du contact."
              onChange={(e) => { setTitle(e.currentTarget.value); }}
            />
            <TextField
              rows="3"
              fullWidth
              multiline
              name="body"
              value={body}
              margin="normal"
              label="Message"
              variant="outlined"
              className={classes.textField}
              helperText="Contenu de la notification qui apparait quand la notification est ouverte par le contact."
              onChange={(e) => { setBody(e.currentTarget.value); }}
            />
            <TextField
              fullWidth
              name="link"
              value={link}
              margin="normal"
              label="Lien de redirection (optionnel)"
              variant="outlined"
              className={classes.textField}
              helperText="Lien vers lequel sera redirigé le contact lorsqu'il cliquera sur la notification."
              onChange={(e) => { setLink(e.currentTarget.value); }}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={() => setOpenDialog(false)} color="primary" style={{ float: 'left' }}>
              {'Fermer'}
            </Button>
            <Button color="primary" variant="contained" disabled={isSubmitting} onClick={handleCreateCampaign}>
              {'Enregistrer'}
            </Button>
          </DialogActions>
        </Dialog>
    </Screen>
  );
});
